<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('reports.investments.view')">
          <actions
            slot="actions"
            crud-links="investments"
            controller="proyectionInvestments"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.value')">
            ${{ investment.value }}
          </text-list>
          <text-list
            v-if="hasNested(investment, 'proyection.district.country.region')"
            :condition="currentUser.can('Regions', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(investment.proyection.district.country.region.name)"
            :to="{ name: 'regionsView', params: {id: investment.proyection.district.country.region.id}}"
          />
          <text-list :title="$t('tables.headings.region_assignment')">
            {{ getAssignment(investment.region_assignment) }}
          </text-list>
          <text-list :title="$t('tables.headings.region_confirmed')">
            {{ getConfirmation(investment.region_confirmation) }}
          </text-list>
          <text-list
            v-if="investment.region_manager"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.region_manager')"
            :label="investment.region_manager.name"
            :to="{ name: 'usersView', params: {id: investment.region_manager.id}}"
          />
          <text-list
            v-if="investment.region_manager"
            :title="$t('tables.headings.region_manager_email')"
          >
            {{ investment.region_manager.email }}
          </text-list>
          <text-list
            v-if="hasNested(investment, 'proyection.district.country')"
            :condition="currentUser.can('Countries', 'view')"
            :title="$t('tables.headings.country')"
            :label="$t(investment.proyection.district.country.name)"
            :to="{ name: 'countriesView', params: {id: investment.proyection.district.country.id}}"
          />
          <text-list :title="$t('tables.headings.country_assignment')">
            {{ getAssignment(investment.country_assignment) }}
          </text-list>
          <text-list :title="$t('tables.headings.country_confirmed')">
            {{ getConfirmation(investment.country_confirmation) }}
          </text-list>
          <text-list
            v-if="investment.country_manager"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.country_manager')"
            :label="investment.country_manager.name"
            :to="{ name: 'usersView', params: {id: investment.country_manager.id}}"
          />
          <text-list
            v-if="investment.country_manager"
            :title="$t('tables.headings.country_manager_email')"
          >
            {{ investment.country_manager.email }}
          </text-list>
          <text-list
            v-if="hasNested(investment, 'proyection.teams')"
            :condition="currentUser.can('Teams', 'view')"
            :title="$t('tables.headings.team')"
            :label="investment.proyection.team.code"
            :to="{ name: 'countriesView', params: {id: investment.proyection.team_id}}"
          />
          <text-list :title="$t('tables.headings.team_assignment')">
            {{ getAssignment(investment.team_assignment) }}
          </text-list>
          <text-list :title="$t('tables.headings.team_confirmed')">
            {{ getConfirmation(investment.team_confirmation) }}
          </text-list>
          <text-list
            v-if="investment.team_leader"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.leader')"
            :label="investment.team_leader.name"
            :to="{ name: 'usersView', params: {id: investment.team_leader.id}}"
          />
          <text-list
            v-if="investment.team_leader"
            :title="$t('tables.headings.team_leader_email')"
          >
            {{ investment.team_leader.email }}
          </text-list>
          <text-list
            v-show="investment.created_at"
            :title="$t('tables.headings.notificated_at')"
          >
            {{ investment.created_at | date }}
          </text-list>
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { hasOwnNestedProperty } from '@/services/utils'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'report-investments-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      error: false,
      investment: {},
      actions: ['index', 'delete'],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created () {
    this.updateData()
  },
  methods: {
    hasNested (obj, property) {
      return hasOwnNestedProperty(obj, property)
    },
    getAssignment (value) {
      let val = (this.investment.value * value).toFixed(2)
      if (val === 'NaN') val = 0
      let percentage = (value * 100).toFixed(2)
      if (percentage === 'NaN') percentage = 0
      return `${percentage}% - $${val}`
    },
    getConfirmation (confirm) {
      let c = 'tables.status.unreviewed'
      switch (confirm) {
        case 1: c = 'layout.yes'
          break
        case -1: c = 'layout.no'
          break
      }
      return this.$t(c)
    },
    routeBuilder (id) {
      return `reports/investments/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const investmentId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(investmentId))
      } catch (err) {
        // console.log('Error fetching investment data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.investment = u.data.data
    },
  },
}
</script>
